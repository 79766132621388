import { 
  auth,
  signInWithEmailAndPassword,
  signOut,
  googleAuthProvider, 
  signInWithPopup,
  createUserWithEmailAndPassword,
  microsoftAuthProvider
} from 'auth/FirebaseAuth';

const FirebaseService = {}

FirebaseService.signInEmailRequest = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password).then(user => user).catch(err => err);
}

FirebaseService.signOutRequest = async () =>
	await signOut(auth).then(user => user).catch(err => err);

FirebaseService.signInGoogleRequest = async () =>
  await signInWithPopup(auth, googleAuthProvider).then(user => user).catch(err => err);

FirebaseService.signInMicrosoftRequest = async () =>
  await signInWithPopup(auth, microsoftAuthProvider).then(user => user).catch(err => err);

FirebaseService.signUpEmailRequest = async (email, password) =>
	await createUserWithEmailAndPassword(auth, email, password).then(user => user).catch(err => err);

FirebaseService.signInWithRefreshToken = async () => {
  const user = auth.currentUser;
  if (user) {
    const accessToken = await auth.currentUser.getIdToken(true); 
    return accessToken ;
  } else {
    throw new Error('Utilisateur non connecté');
  }
}
  
	
export default FirebaseService