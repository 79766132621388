import React, { useEffect, useState } from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { protectedRoutes, publicRoutes } from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import { useDispatch, useSelector } from 'react-redux';
import { endPointConstant } from 'constants/EndPointConstant';
import { AUTH_TOKEN, TOKEN } from 'constants/AuthConstant';
import { Space, Spin } from 'antd';
import { saveUserData } from 'store/slices/userSlice';
import store from 'store';
import { signOutSuccess } from 'store/slices/authSlice';
import { generateNavList } from 'configs/NavigationConfig';

const Routes = () => {

	const user = useSelector(state => state.user);
	const token = useSelector(state => state.auth.token);
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [routeList, setRouteList] = useState([])

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(endPointConstant.GET_USER_INFOS, {
					method: 'GET',
					headers: {
						'Authorization': localStorage.getItem(TOKEN),
					},
				});
				if (!response.ok) {
					throw new Error('La requête a échoué');
				}
				const data = await response.json();
				dispatch(saveUserData(data));
				setRouteList(generateNavList(data?.listNavigation))
				window.dispatchEvent(new Event("storage"))
			} catch (error) {
				setIsLoading(false);
				localStorage.removeItem(AUTH_TOKEN)
				localStorage.removeItem(TOKEN);
				localStorage.removeItem("user");
				store.dispatch(signOutSuccess());
				console.error('Erreur lors de la requête fetch :', error);
			} finally {
				setIsLoading(false);
			}
		};

		if (token) {
			fetchData();
		} else {
			setIsLoading(false);
		}

	}, [token, dispatch]);

	if (isLoading) {
		return <div className="app-content d-flex align-items-center justify-content-center">  
				<Space size="middle">
					<Spin size="large" />
				</Space>
			</div>;
	}


	return (
		<RouterRoutes>
			<Route path="/" element={<ProtectedRoute />}>
				<Route path="/" element={<Navigate replace to={routeList[0]?.submenu[0]?.path} />} />
				{protectedRoutes.map((route, index) => {
					if (user?.listNavigation.includes(route?.groupKey)) {
						return (
							<Route 
								key={route.key + index} 
								path={route.path}
								element={
									<AppRoute
										routeKey={route.key} 
										component={route.component}
										{...route.meta} 
									/>
								}
							/>
						)
					}
				})}
				<Route path="*" element={<Navigate to="/" replace />} />
			</Route>
			<Route path="/" element={<PublicRoute />}>
				{publicRoutes.map(route => {
					return (
						<Route 
							key={route.path} 
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key} 
									component={route.component}
									{...route.meta} 
								/>
							}
						/>
					)
				})}
			</Route>

		</RouterRoutes>
	)
}

export default Routes