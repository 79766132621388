// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/NeueHaasDisplayLight.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/RubikLight.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "NeueHaasDisplay";   /*Can be any text*/
  src: local("NeueHaasDisplay"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

@font-face {
  font-family: "Rubik";   /*Can be any text*/
  src: local("Rubik"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #FF6900;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B,IAAI,kBAAkB;EACpD;8DAC4D;AAC9D;;AAEA;EACE,oBAAoB,IAAI,kBAAkB;EAC1C;8DACkD;AACpD;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EAEA,gCAAgC;AAC1C;AACA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,cAAc;AAChB","sourcesContent":["@font-face {\r\n  font-family: \"NeueHaasDisplay\";   /*Can be any text*/\r\n  src: local(\"NeueHaasDisplay\"),\r\n    url(\"./fonts/NeueHaasDisplayLight.ttf\") format(\"truetype\");\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Rubik\";   /*Can be any text*/\r\n  src: local(\"Rubik\"),\r\n    url(\"./fonts/RubikLight.ttf\") format(\"truetype\");\r\n}\r\n\r\n.loading.cover-content {\r\n  position: absolute;\r\n  left: 50%;\r\n  top: 50%;\r\n  -webkit-transform: translate(-50%, -50%);\r\n          transform: translate(-50%, -50%);\r\n}\r\n.loading.cover-page {\r\n  position: fixed;\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n.loading .ant-spin {\r\n  color: #FF6900;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
