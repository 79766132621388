import fetch from 'auth/FetchInterceptor';
import { from } from 'rxjs';

const apiServiceCustom = {}

const baseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

apiServiceCustom.getRequest = function (endpoint, params) {
    return from(fetch({
        url: baseUrl + endpoint,
        method: 'get',
        params
    }));
}

apiServiceCustom.postRequest = function (endpoint, data) {
    return from(fetch({
        url: baseUrl + endpoint,
        method: 'post',
        data: data
    }));
}

apiServiceCustom.putRequest = function (endpoint, data) {
    return from(fetch({
        url: baseUrl + endpoint,
        method: 'put',
        data: data
    }));
}

apiServiceCustom.deleteRequest = function (endpoint, data) {
    return from(fetch({
        url: baseUrl + endpoint,
        method: 'delete',
        data: data
    }));
}

export default apiServiceCustom