import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH, EXTERNAL_FORM } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'login-1',
        path: `${AUTH_PREFIX_PATH}/login-1`,
        component: React.lazy(() => import('views/auth-views/authentication/login-1')),
    },
    {
        key: 'login-2',
        path: `${AUTH_PREFIX_PATH}/login-2`,
        component: React.lazy(() => import('views/auth-views/authentication/login-2')),
    },
    {
        key: 'register-1',
        path: `${AUTH_PREFIX_PATH}/register-1`,
        component: React.lazy(() => import('views/auth-views/authentication/register-1')),
    },
    {
        key: 'register-2',
        path: `${AUTH_PREFIX_PATH}/register-2`,
        component: React.lazy(() => import('views/auth-views/authentication/register-2')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'error-page-1',
        path: `${AUTH_PREFIX_PATH}/error-page-1`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
    },
    {
        key: 'error-page-2',
        path: `${AUTH_PREFIX_PATH}/error-page-2`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
    },
    {
        key: 'form',
        path: `${EXTERNAL_FORM}/form`,
        component: React.lazy(() => import('views/unauth-views/extranet-form')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'form-error',
        path: `${EXTERNAL_FORM}/form-error`,
        component: React.lazy(() => import('views/unauth-views/extranet-form-error')),
        meta: {
            blankLayout: true
        }
    }
]

export let protectedRoutes = [
    {
        groupKey: 'MONITORING',
        key: 'dashboard.monitoring',
        path: `${APP_PREFIX_PATH}/dashboards/monitoring`,
        component: React.lazy(() => import('views/app-views/dashboards/monitoring')),
    },
    {
        groupKey: 'MONITORING',
        key: 'dashboard.sales',
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        component: React.lazy(() => import('views/app-views/dashboards/sales')),
    },
    {
        groupKey: 'LOWCARBON',
        key: 'dashboard.lowcarbon',
        path: `${APP_PREFIX_PATH}/dashboards/lowcarbon`,
        component: React.lazy(() => import('views/app-views/dashboards/lowcarbon')),
    },
    {
        groupKey: 'LOWCARBON',
        key: 'dashboard.lowcarbonContents',
        path: `${APP_PREFIX_PATH}/dashboards/lowcarbonContents`,
        component: React.lazy(() => import('views/app-views/dashboards/lowcarbonContents')),
    },
    {
        groupKey: 'LOWCARBON',
        key: 'dashboard.lowcarbonCommunities',
        path: `${APP_PREFIX_PATH}/dashboards/lowcarbonCommunities`,
        component: React.lazy(() => import('views/app-views/dashboards/lowcarbonCommunities')),
    },
    {
        groupKey: 'CONFIGURATION',
        key: 'configuration.group',
        path: `${APP_PREFIX_PATH}/configuration/group`,
        component: React.lazy(() => import('views/app-views/config/group/group.js')),
        meta: {
            blankLayout: false
        }
    },
    {
        groupKey: 'CONFIGURATION',
        key: 'configuration.settings',
        path: `${APP_PREFIX_PATH}/configuration/settings`,
        component: React.lazy(() => import('views/app-views/config/settings/settings.js')),
        meta: {
            blankLayout: false
        }
    },
    {
        groupKey: 'EXTRANET',
        key: 'extranet.add-users',
        path: `${APP_PREFIX_PATH}/extranet/add-users`,
        component: React.lazy(() => import('views/app-views/apps/extranet/users')),
    },
    {
        groupKey: 'EXTRANET',
        key: 'EXTRANET',
        path: `${APP_PREFIX_PATH}/extranet/user-list`,
        component: React.lazy(() => import('views/app-views/apps/extranet/user-list')),
    },
    {
        groupKey: 'EXTRANET',
        key: 'EXTRANET',
        path: `${APP_PREFIX_PATH}/extranet/awaiting-requests`,
        component: React.lazy(() => import('views/app-views/apps/extranet/awaiting-requests')),
    },
    {
        groupKey: 'LOWCARBON',
        key: 'pages.faq',
        path: `${APP_PREFIX_PATH}/pages/faq`,
        component: React.lazy(() => import('views/app-views/pages/faq')),
    }
]

export const configurationRoutes = [
    {
        key: 'configuration',
        path: `${APP_PREFIX_PATH}/configuration/group`,
        component: React.lazy(() => import('views/app-views/config/group/group.js')),
        meta: {
            blankLayout: false
        }
    },
]

export const monitoringRoutes = [
    {
        key: 'dashboard.monitoring',
        path: `${APP_PREFIX_PATH}/dashboards/monitoring`,
        component: React.lazy(() => import('views/app-views/dashboards/monitoring')),
    },
    {
        key: 'dashboard.sales',
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        component: React.lazy(() => import('views/app-views/dashboards/sales')),
    }
]

export const extranetRoutes = [
    {
        key: 'extranet.add-users',
        path: `${APP_PREFIX_PATH}/extranet/add-users`,
        component: React.lazy(() => import('views/app-views/apps/extranet/users')),
    },
    {
        key: 'extranet.user-list',
        path: `${APP_PREFIX_PATH}/extranet/user-list`,
        component: React.lazy(() => import('views/app-views/apps/extranet/user-list')),
    },
    {
        key: 'extranet.awaiting-requests',
        path: `${APP_PREFIX_PATH}/extranet/awaiting-requests`,
        component: React.lazy(() => import('views/app-views/apps/extranet/awaiting-requests')),
    }
]

export const lowCarbonRoutes = [
    {
        key: 'dashboard.lowcarbon',
        path: `${APP_PREFIX_PATH}/dashboards/lowcarbon`,
        component: React.lazy(() => import('views/app-views/dashboards/lowcarbon')),
    },
    {
        key: 'dashboard.lowcarbonContents',
        path: `${APP_PREFIX_PATH}/dashboards/lowcarbonContents`,
        component: React.lazy(() => import('views/app-views/dashboards/lowcarbonContents')),
    },
    {
        key: 'dashboard.lowcarbonCommunities',
        path: `${APP_PREFIX_PATH}/dashboards/lowcarbonCommunities`,
        component: React.lazy(() => import('views/app-views/dashboards/lowcarbonCommunities')),
    },
    {
        key: 'pages.faq',
        path: `${APP_PREFIX_PATH}/pages/faq`,
        component: React.lazy(() => import('views/app-views/pages/faq')),
    }
]
