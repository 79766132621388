const FirebaseConfig = {
  apiKey: "AIzaSyBEvJeM69ffQLB_HcJPXe5CJ3osq5mz5tc",
  authDomain: "smart-proxy-374918.firebaseapp.com",
  projectId: "smart-proxy-374918",
  storageBucket: "smart-proxy-374918.appspot.com",
  messagingSenderId: "933562570960",
  appId: "1:933562570960:web:ec315ab834c579f0ca66d5",
  measurementId: "G-VS4EGRH7GN"
};

export default FirebaseConfig