import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import history from './history'
import Layouts from './layouts'
import { THEME_CONFIG } from './configs/AppConfig';
import './lang'
import { auth } from 'auth/FirebaseAuth';
import { onLocaleChange } from 'store/slices/themeSlice';
import i18n from 'i18next'

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    // Récupérer la langue depuis le localStorage
    const selectedLanguage = localStorage.getItem('language');
    if (selectedLanguage === 'en' || selectedLanguage === 'fr') {
      dispatch(onLocaleChange(selectedLanguage));
      i18n.changeLanguage(selectedLanguage);
    }

    const unsubscribe = auth.onAuthStateChanged(user => user);
    // Le retour de cette fonction de rappel permet de se désabonner de l'écouteur
    return () => unsubscribe();
  }, []);

  return (
    <div className="App">
      <BrowserRouter history={history}>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={THEME_CONFIG.currentTheme}
          insertionPoint="styles-insertion-point"
        >
          <Layouts />
        </ThemeSwitcherProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
