export const endPointConstant = {
    GET_USER: "/getUser",
    GET_ALL_ORG_UNIT_PATH: "/davap/getAllOrgUnitPath",
    GET_USER_LIST: "/davap/extranet/getUsers",
    GET_CHART_USERS_MONITORING_PAGE: "/davap/getChartUsersMonitoringPage",
    GET_CONTENTS_SIZE_BY_TYPE: "/davap/lowCarbon/getContentsSizeByType",
    GET_ANNUAL_STATISTIC_DATA: "/davap/getAnnualStatisticData",
    GET_UNUSED_BUT_PAIED_LICENSES: "/davap/getUnusedButPaidLicenses",
    GET_ALL_COMMUNITIES:"/davap/getAllCommunities",
    GET_CHART_COMMUNITY_CONTENTS: "/davap/getChartCommunityContents",
    GET_CHART_CONTENTS: "/davap/getChartContents",
    GET_COUNT_BY_ORG: "/davap/getCountByOrg",
    GET_EXTRANET_FORM_INFOS: "/davap/extranet/getFormInfos",
    GET_CUSTOMER_LIST: "/davap/customer/getCustomerList",
    GET_INSTANCE_LIST: "/davap/customer/instance/list",
    GET_CUSTOMER_SETTINGS: "/davap/customer/getSettings",
    GET_CUSTOMER_SCRAP_CONTENT: "/davap/customer/scrap/content",
    GET_USER_INFOS: "/davap/user/getInfos",
    GET_GROUP_LIST: "/davap/group/list",
    GET_GROUP_USER_LIST: "/davap/group/user/list",
    GET_CONTENT_SCORE: "/davap/content/score",
    GET_CONTENT_INFOS: "/davap/content/infos",
    GET_CONTENT_HISTORY: "/davap/content/history",
    GET_AWAITING_REQUEST: "/davap/extranet/getAwaitingRequest",
    
    UPDATE_USER: "/davap/extranet/updateUser",
    UPDATE_USER_CUSTOMER: "/davap/user/putCustomer",
    UPDATE_AWAITING_REQUEST: "/davap/extranet/updateAwaitingRequest",

    POST_EXTRANET_USER: "/davap/extranet/postUsers",
    POST_USERS_AWAITING_REQUESTS: "/davap/postUsersAwaitingRequests",
    POST_GROUP: "/davap/group/postGroup",
    POST_GROUP_SYNCHRONIZE: "/davap/group/synchronize",
    POST_CUSTOMER_SETTINGS: "/davap/customer/postSettings",
    POST_CUSTOMER_TOKEN: "/davap/customer/token",
    POST_CONTENT_LIST: "/davap/content/list",
    POST_CONTENT: "/davap/content/save",
    POST_CONTENT_CATEGORY_LIST: "/davap/content/category/list",
    

    DELETE_CONTENT_LIST: "/davap/content/delete",
}

export const publicEndpointConstant = {
    GET_TOKEN_VALIDITY: "/davap/public/extranet/getTokenValidity",
    POST_DAVAP_SIGN_UP: "/davap/public/extranet/signUp"
}
