import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { refreshToken, signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import { AUTH_TOKEN, EXTERNAL_TOKEN, TOKEN } from 'constants/AuthConstant';
import { notification } from 'antd';
import { publicEndpointConstant } from 'constants/EndPointConstant';

const unauthorizedCode = [401]

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
})

// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

// API Request interceptor
service.interceptors.request.use(config => {
	const jwtToken = localStorage.getItem(isExternalEndpoint(config?.url) ? EXTERNAL_TOKEN : TOKEN)
	if (jwtToken) {
		config.headers[TOKEN_PAYLOAD_KEY] = jwtToken
	}

  	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	return response.data
}, async (error) => {

	let notificationParam = {
		message: ''
	}
 
	// Remove token and redirect 
	if (unauthorizedCode.includes(error.response?.status) && !isExternalEndpoint(error?.config?.url)) {
		const originalRequest = error.config;
		if (!originalRequest.__isRetryRequest) {
			try {
				originalRequest.__isRetryRequest = true;

				await store.dispatch(refreshToken());
				originalRequest.headers.Authorization = localStorage.getItem(TOKEN);
	
				// Make a new request with the updated formData as the body
				const response = await axios(originalRequest);
				return response.data;
			} catch (e) {}
		}
		notificationParam.message = 'Authentication Fail'
		notificationParam.description = 'Please login again'
		localStorage.removeItem(AUTH_TOKEN)
		localStorage.removeItem("TOKEN");
		localStorage.removeItem("user");
		store.dispatch(signOutSuccess());
	}

	if (error.response?.status === 400) {
		notificationParam.message = 'Bad request'
	}

	if (error.response?.status === 401) {
		notificationParam.message = 'Unauthorized'
	}

	if (error.response?.status === 403) {
		notificationParam.message = 'Forbidden'
	}

	if (error.response?.status === 404) {
		notificationParam.message = 'Not Found'
	}

	if (error.response?.status === 500) {
		notificationParam.message = 'Internal Server Error'
	}
	
	if (error.response?.status === 508) {
		notificationParam.message = 'Time Out'
	}

	notification.error(notificationParam)

	return Promise.reject(error);
});

function isExternalEndpoint(url) {
	var endpoint = url?.split(process.env.REACT_APP_BASE_URL)[1]
	if (Object.values(publicEndpointConstant).includes(endpoint)) {
		return true;
	  } else {
		return false;
	  }
}

export default service