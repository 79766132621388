import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
	admin: false,
	customer: '',
    listNavigation: []
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
        saveUserData: (state, action) => {
			state.admin = action.payload?.admin
            state.customer = action.payload?.customer
            state.listNavigation = action.payload?.listNavigation
			state.fullName = action.payload?.fullName
			state.email = action.payload?.email
			state.userId = action.payload?.userId
		}
	},
})

export const { 
    saveUserData
} = userSlice.actions

export default userSlice.reducer